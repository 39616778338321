import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import './index.css';
import App from './App';

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
// setup fake backend
import { configureFakeBackend } from './_helpers';
configureFakeBackend();
//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
// disable ServiceWorker
// registerServiceWorker();

import React from 'react';
import Loadable from 'react-loadable'


function Loading() {
  return <div>Loading...</div>;
}



const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const UpdateUser = Loadable({
  loader: () => import('./views/Users/Update'),
  loading: Loading,
});

const Categories = Loadable({
  loader: () => import('./views/Categories/Categories'),
  loading: Loading,
});
const AddCategories = Loadable({
  loader: () => import('./views/Categories/Add'),
  loading: Loading,
});
const UpdateCategories = Loadable({
  loader: () => import('./views/Categories/Update'),
  loading: Loading,
});
const AdminUsers = Loadable({
  loader: () => import('./views/Adminusers/Users'),
  loading: Loading,
});
const AddAdminUser = Loadable({
  loader: () => import('./views/Adminusers/Add'),
  loading: Loading,
});
const UpdateAdminUser = Loadable({
  loader: () => import('./views/Adminusers/Update'),
  loading: Loading,
});

const Post = Loadable({
  loader: () => import('./views/Post/Posts'),
  loading: Loading,
});

const AddPost = Loadable({
  loader: () => import('./views/Post/Add'),
  loading: Loading,
});
const UpdatePost = Loadable({
  loader: () => import('./views/Post/Update'),
  loading: Loading,
});

const SubmittedPost = Loadable({
  loader: () => import('./views/Submittedposts/Posts'),
  loading: Loading,
});

const ViewSubmittedPosts = Loadable({
  loader: () => import('./views/Submittedposts/View'),
  loading: Loading,
});


const AddLanguages = Loadable({
  loader: () => import('./views/Languages/Add'),
  loading: Loading,
});
const UpdateLanguage = Loadable({
  loader: () => import('./views/Languages/Update'),
  loading: Loading,
});

const Languages = Loadable({
  loader: () => import('./views/Languages/Languages'),
  loading: Loading,
});

const AddTranslate = Loadable({
  loader: () => import('./views/Translations/Add'),
  loading: Loading,
});
const UpdateTranslate = Loadable({
  loader: () => import('./views/Translations/Update'),
  loading: Loading,
});

const Translations = Loadable({
  loader: () => import('./views/Translations/Translations'),
  loading: Loading,
});


const Advertisement = Loadable({
  loader: () => import('./views/Advertisements/Advertisements'),
  loading: Loading,
});

const DefaultAdvertisement = Loadable({
  loader: () => import('./views/DefaultAdvertisements/Advertisements'),
  loading: Loading,
});

const SubmittedAdvertisements = Loadable({
  loader: () => import('./views/Advertisements/SubmittedAdvertisements'),
  loading: Loading,
});

const AddDefaultAdvertisement = Loadable({
  loader: () => import('./views/DefaultAdvertisements/Add'),
  loading: Loading,
});
const UpdateDefaultAdvertisement = Loadable({
  loader: () => import('./views/DefaultAdvertisements/Update'),
  loading: Loading,
});

const AddAdvertisement = Loadable({
  loader: () => import('./views/Advertisements/Add'),
  loading: Loading,
});
const UpdateAdvertisement = Loadable({
  loader: () => import('./views/Advertisements/Update'),
  loading: Loading,
});

const FooterLinks = Loadable({
  loader: () => import('./views/FooterLinks/Links'),
  loading: Loading,
});

const FooterLinkAdd = Loadable({
  loader: () => import('./views/FooterLinks/Add'),
  loading: Loading,
});

const FooterLinkUpdate = Loadable({
  loader: () => import('./views/FooterLinks/Update'),
  loading: Loading,
});

const HomePageCategories = Loadable({
  loader: () => import('./views/HomepageCategories/View'),
  loading: Loading,
});
const FeaturedBlock = Loadable({
  loader: () => import('./views/FeaturedBlock/View'),
  loading: Loading,
});
const DefaultBlock = Loadable({
  loader: () => import('./views/DefaultBlock/View'),
  loading: Loading,
});
const Settings = Loadable({
  loader: () => import('./views/Settings/View'),
  loading: Loading,
});
const Payments = Loadable({
  loader: () => import('./views/Payments/List'),
  loading: Loading,
});
const EarningsUsers = Loadable({
  loader: () => import('./views/EarningsUsers/List'),
  loading: Loading,
});
const Earnings = Loadable({
  loader: () => import('./views/Earnings/List'),
  loading: Loading,
});
const ViewEarning = Loadable({
  loader: () => import('./views/Earnings/View'),
  loading: Loading,
});
const Payouts = Loadable({
  loader: () => import('./views/Payouts/List'),
  loading: Loading,
});
const PayoutsView = Loadable({
  loader: () => import('./views/Payouts/View'),
  loading: Loading,
});


const LiveStream = Loadable({
  loader: () => import('./views/LiveStreams/List'),
  loading: Loading,
});
const LiveStreamUpdate = Loadable({
  loader: () => import('./views/LiveStreams/Update'),
  loading: Loading,
});
const LiveStreamAdd = Loadable({
  loader: () => import('./views/LiveStreams/Add'),
  loading: Loading,
});
const Incomes = Loadable({
  loader: () => import('./views/Incomes/List'),
  loading: Loading,
});
const Views = Loadable({
  loader: () => import('./views/Views/List'),
  loading: Loading,
});
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/user/update/:id', exact: true, name: 'User Details', component: UpdateUser },
  { path: '/categories', exact: true, name: 'Categories', component: Categories },
  { path: '/categories/add', exact: true, name: 'Add Category', component: AddCategories },
  { path: '/categories/update/:id', exact: true, name: 'Update Category', component: UpdateCategories },
  { path: '/adminusers', exact: true,  name: 'Admin Users', component: AdminUsers },
  { path: '/adminuser/add', exact: true, name: 'Add Admin User', component: AddAdminUser },
  { path: '/adminuser/update/:id', exact: true, name: 'Update Admin User', component: UpdateAdminUser },
  { path: '/post', exact: true, name: 'Post', component: Post },
  { path: '/post/add', exact: true, name: 'Add Post', component: AddPost },
  { path: '/post/update/:id', exact: true, name: 'Update Post', component: UpdatePost },
  { path: '/submitted-post', exact: true, name: 'Submitted Post', component: SubmittedPost },
  { path: '/submitted-post/view/:id', exact: true, name: 'View Submitted Post', component: ViewSubmittedPosts },
  { path: '/advertisements', exact: true, name: 'Advertisement', component: Advertisement },
  { path: '/submitted-advertisements', exact: true, name: 'Submitted Advertisements', component: SubmittedAdvertisements },
  { path: '/advertisement/add', exact: true, name: 'Add Advertisement', component: AddAdvertisement },
  { path: '/advertisement/update/:id', exact: true, name: 'Update Advertisement', component: UpdateAdvertisement },
  { path: '/footer-links', exact: true, name: 'Footer Links', component: FooterLinks },
  { path: '/footer-link/add', exact: true, name: 'Add Footer Link', component: FooterLinkAdd },
  { path: '/footer-link/update/:id', exact: true, name: 'Update Footer Link', component: FooterLinkUpdate },
  { path: '/homepage-categories', exact: true, name: 'Home page categories', component: HomePageCategories },
  { path: '/featured-block', exact: true, name: 'Home page featured block', component: FeaturedBlock },
  { path: '/default-block', exact: true, name: 'Home page gefault block', component: DefaultBlock },
  { path: '/settings', exact: true, name: 'Settings', component: Settings },
  { path: '/payments', exact: true, name: 'Payments', component: Payments },
  { path: '/user-earnings', exact: true, name: 'EarningsUsers', component: EarningsUsers },
  { path: '/earnings', exact: true, name: 'Earnings', component: Earnings },
  { path: '/earnings/view/:id', exact: true, name: 'ViewEarning', component: ViewEarning },
  { path: '/payouts', exact: true, name: 'Payouts', component: Payouts },
  { path: '/payouts/view/:id', exact: true, name: 'PayoutsView', component: PayoutsView },
  { path: '/defaultadvertisements', exact: true, name: 'DefaultAdvertisement', component: DefaultAdvertisement },
  { path: '/defaultadvertisement/add', exact: true, name: 'AddDefaultAdvertisement', component: AddDefaultAdvertisement },
  { path: '/defaultadvertisement/update/:id', exact: true, name: 'UpdateDefaultAdvertisement', component: UpdateDefaultAdvertisement },
  { path: '/livestream', exact: true, name: 'LiveStream', component: LiveStream },
  { path: '/livestream/add', exact: true, name: 'LiveStreamAdd', component: LiveStreamAdd },
  { path: '/livestream/update/:id', exact: true, name: 'LiveStreamUpdate', component: LiveStreamUpdate },
  { path: '/incomes', exact: true, name: 'Incomes', component: Incomes },
  { path: '/views', exact: true, name: 'Views', component: Views },

  { path: '/langauges', exact: true, name: 'Languages', component: Languages },
  { path: '/langauges/add', exact: true, name: 'Add Language', component: AddLanguages},
  { path: '/langauges/update/:id', exact: true, name: 'Update Language', component: UpdateLanguage },

  { path: '/translations', exact: true, name: 'Translations', component: Translations },
  { path: '/translations/add', exact: true, name: 'Add Translate', component: AddTranslate},
  { path: '/translations/update/:id', exact: true, name: 'Update Translate', component: UpdateTranslate },
];

export default routes;

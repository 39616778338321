import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom';
import { DropdownItem, Nav, NavItem} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.svg'
import sygnet from '../../assets/img/brand/sygnet.svg'
import { userActions } from '../../_actions';
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
      super(props);
      this.state = {isLoggedIn:true};
      this.userLogout = this.userLogout.bind(this);
    }
  userLogout(e) {
      this.props.dispatch(userActions.logout());
      this.setState({isLoggedIn: false});
  }
  render() {

    if(!this.state.isLoggedIn){
      return <Redirect to={{ pathname: '/login' }} />
    }
    return (

      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <Link to="/">Dashboard</Link>
          </NavItem>
          <NavItem className="px-3">
            <Link to="/adminusers">Admin Users</Link>
          </NavItem>
          <NavItem className="px-3">
            <Link to="/settings">Settings</Link>
          </NavItem>
          <NavItem className="px-3">
            <Link to="/langauges">Languages</Link>
          </NavItem>
          
        </Nav>
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownItem onClick={this.userLogout}><i className="fa fa-lock"></i> Logout</DropdownItem>
          </AppHeaderDropdown>
        </Nav>

        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const DefaultHeaderExp = connect(mapStateToProps)(DefaultHeader);
export default DefaultHeaderExp;
